import './App.css';
//Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
//Pages
import Home from './pages/Home';
import Photos from './pages/Photos';
import Localization from './pages/Localization';
import Ratings from './pages/Ratings';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className='container'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/photos' element={<Photos />} />
            <Route path='/localization' element={<Localization />} />
            <Route path='/ratings' element={<Ratings />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
