import React from 'react'
import './Photo.css'

//Modal
import Modal from 'react-modal';

//Hooks
import { useState } from 'react';

//Icons
import { ImCross } from 'react-icons/im';

//Photos
import piscina2 from '../photos/piscina2.jpeg';
import piscina5 from '../photos/piscina5.jpeg';
import piscina6 from '../photos/piscina6.jpeg';
import banheiro1 from '../photos/banheiro1.jpeg';
import banheiro2 from '../photos/banheiro2.jpeg';
import banheiro3 from '../photos/banheiro3.jpeg';
// import banheiro4 from '../photos/banheiro4.jpeg';
import banheiro5 from '../photos/banheiro5.jpeg';
import cozinha3 from '../photos/cozinha3.jpeg';
import entrada from '../photos/entrada.jpeg';
import mesa from '../photos/mesa.jpeg';
import mesa2 from '../photos/mesa2.jpeg';
import quarto1 from '../photos/quarto1.jpeg';
import quarto2 from '../photos/quarto2.jpeg';
import quarto3 from '../photos/quarto3.jpeg';
import sala1 from '../photos/sala1.jpeg';
import varanda2 from '../photos/varanda2.jpeg';
import varanda3 from '../photos/varanda3.jpeg';


import cozinha1 from '../photos/cozinha1.jpeg';
import cozinha2 from '../photos/cozinha2.jpeg';
import area1 from '../photos/area1.jpeg';
// import area2 from '../photos/area2.jpeg';
import varanda1 from '../photos/varanda1.jpeg';
// import piscina4 from '../photos/piscina4.jpeg';

const Photos = () => {
  //Modals div
  Modal.setAppElement('#root');

  const Photos = [entrada, mesa2, sala1, mesa, banheiro5, cozinha3, cozinha1, cozinha2, area1, quarto3, quarto2, quarto1, banheiro1, banheiro2, banheiro3, varanda3, varanda2, piscina6, piscina2, piscina5, varanda1];

  const [isOpen, setIsOpen] = useState(false);
  const [actualPhoto, setActualPhoto] = useState('');

  const openModal = (photo) => {
    setActualPhoto(photo);
    console.log(photo);
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }


  return (
    <div id='photo-page'>
      <h1>Conheça nossas acomodações</h1>
      <div className='photos-container'>
        {Photos && Photos.map(photo => (
          <div className='photo' onClick={() => openModal(photo)} key={photo}>
            <img src={photo} alt='' />
          </div>
        )
        )}

        {/* Modal */}
        <Modal isOpen={isOpen} onRequestClose={closeModal} className='modal-content' overlayClassName='modal-overlay'>
          <ImCross onClick={closeModal} />
          <img src={actualPhoto} alt='' />
        </Modal>
      </div>
    </div>
  )
}

export default Photos