import React from 'react'
import './Navbar.css';
import { Link, NavLink } from 'react-router-dom';
import { BsFillSuitDiamondFill } from 'react-icons/bs';

const Navbar = () => {
    return (
        <div id='navbar'>
                <div id='titulo'>
                    <Link to="/" id='nav'>
                        <h2>Apartamento de temporada da Jô</h2>
                        <p>Cabo Frio - RJ</p>
                    </Link>
                </div>
            <ul id="nav-links">
                <li>
                    <NavLink to='/'>
                        <BsFillSuitDiamondFill />
                        Início
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/photos'>
                        <BsFillSuitDiamondFill />
                        Fotos
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/localization'>
                        <BsFillSuitDiamondFill />
                        Localização
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/ratings'>
                        <BsFillSuitDiamondFill />
                        Avaliações
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Navbar