import React from 'react';
import './Localization.css';

const Localization = () => {
  return (
    <div id='localization-page'>
      <h1>Localização do prédio</h1>
      <div className="google">
        <div className='localDetails'>
          <p >Como mostra o mapa, o prédio fica na quadra da Praia do Forte, e próximo ao centro da cidade.</p>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.7749368630352!2d-42.0233334844107!3d-22.88477094284061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x971b35273e5881%3A0x4b17509a3fe30c48!2sR.%20Francisco%20Paranhos%2C%2061%20-%20Vila%20Nova%2C%20Cabo%20Frio%20-%20RJ%2C%2028907-320!5e0!3m2!1spt-BR!2sbr!4v1625770990287!5m2!1spt-BR!2sbr" title='maps' width="100%" height="600" loading="lazy"></iframe>
        {/*  width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" */}
      </div>
    </div>
  )
}

export default Localization