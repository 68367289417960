import React from 'react'
import './Home.css';
import { Link } from 'react-router-dom';

//Photos
import praiaForte from '../photos/praiaForte.jpg';
import ilhaJapones from '../photos/ilhaJapones.jpg';
import praiaConchas from '../photos/praiaConchas.jpg';
import sala1 from '../photos/sala1.jpeg';
import local from '../photos/local.JPG';
import notas from '../photos/notas.jpg';

//Icons
import { RiWhatsappFill } from 'react-icons/ri';
import { RiInstagramFill } from 'react-icons/ri';
import { TbBrandAirbnb } from 'react-icons/tb';

const Home = () => {
  return (
    <div id='home-page'>

      <h1>Bem-vindo a Cabo Frio!</h1>
      <div className='overall-ratings bg-blue'>
        <div className='image-caboFrio '>
          <img src={praiaForte} alt="média" />
        </div>
        <div className='details'>
          <h2>Praia do Forte</h2>
          <p>
            Venha conhecer uma das cidades mais bonitas do Brasil, essa foto é da Praia do Forte, a principal praia da cidade e fica à apenas 2 ruas da nossa acomodação.
          </p>
        </div>
      </div>
      <div className='overall-ratings bg-blue'>
        <div className='image-caboFrio '>
          <img src={ilhaJapones} alt="média" />
        </div>
        <div className='details order'>
          <h2>Ilha do Japonês</h2>
          <p>
            A ilha do japonês é famosa por sua água bem clarinha e calma, chegando cedo você pode atravessar da praia para ilha e conhecer e desfrutar da beleza que é esse lugar.
          </p>
        </div>
      </div>
      <div className='overall-ratings bg-blue'>
        <div className='image-caboFrio '>
          <img src={praiaConchas} alt="média" />
        </div>
        <div className='details'>
          <h2>Praia das Conchas</h2>
          <p>
            A praia das conchas fica um pouco mais isolada, é verdade, mas esse fato também é positivo, pois ela é mais calma, e um dos diferenciais dela é que ela tem restaurantes e barracas para te atender.
          </p>
        </div>
      </div>
      <div className='overall-ratings bg-blue'>
        <div className='reserve'>
          <h2>Reserve agora clicando nos ícones a seguir:</h2>
          <div id='social'>
            <a href="https://api.whatsapp.com/send?phone=5522981062471&text=Ol%C3%A1%2C%20quero%20informa%C3%A7%C3%B5es%20do%20apt!" target={'_blank'} rel={'noreferrer'}>
              <RiWhatsappFill />
            </a>
            <RiInstagramFill />
            <a href='https://www.airbnb.com.br/rooms/34527798?_set_bev_on_new_domain=1656800754_MTRhMWJhMDJmODEz&source_impression_id=p3_1656800755_SMH%2FCXQ5YNQ7p4yo' target={'_blank'} rel={'noreferrer'}>
              <TbBrandAirbnb />
            </a>
          </div>
          <p>
            Garanta a sua estadia e venha aproveitar essas e muitas outras atrações na região dos lagos!
          </p>
        </div>
      </div>
      <Link to='/photos'>
        <div className='overall-ratings bg-blue'>
          <div className='image-caboFrio '>
            <img src={sala1} alt="média" />
          </div>
          <div className='details order'>
            <h2>Fotos</h2>
            <p>
              Clique e veja todas as fotos do apartamento e do prédio em detalhes!
            </p>
          </div>
        </div>
      </Link>
      <Link to='/localization'>
        <div className='overall-ratings bg-blue'>
          <div className='image-caboFrio '>
            <img src={local} alt="média" />
          </div>
          <div className='details'>
            <h2>Localização</h2>
            <p>
              Confira a localização exata a partir do Google Maps clicando aqui.
            </p>
          </div>
        </div>
      </Link>
      <Link to='/ratings'>
        <div className='overall-ratings bg-blue'>
          <div className='image-caboFrio '>
            <img src={notas} alt="média" />
          </div>
          <div className='details order'>
            <h2>Avaliações</h2>
            <p>
              Veja como foi a experiência dos hóspedes que já passaram por aqui.
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Home