import React from 'react';
import './Ratings.css';

//Photos
import Notas from '../photos/notas.jpg';
import Depoimento1 from '../photos/depoimento6.JPG';
import Depoimento2 from '../photos/depoimento1.JPG';
import Depoimento3 from '../photos/depoimento2.JPG';
import Depoimento4 from '../photos/depoimento3.JPG';
import Depoimento5 from '../photos/depoimento4.JPG';
import Depoimento6 from '../photos/depoimento5.JPG';

const Ratings = () => {
  return (
    <div id='ratings-page'>
      <h1>Nossas avaliações</h1>
      <p className='p1'>Veja o que os nossos hóspedes disseram</p>
      <div className='overall-ratings'>
        <div className='image'>
          <img src={Notas} alt="média" />
        </div>
        <div className='details'>
          <h2>Média</h2>
          <p>
            Essas são as nossas notas no Airbnb, o maior aplicativo de aluguel de imóveis no mundo.
            Confira os depoimentos das pessoas nos cards abaixo.
          </p>
        </div>
      </div>
      <div className='overall-ratings'>
        <div className='image avaliacoes'>
          <img src={Depoimento1} alt="depoimento1" />
          <img src={Depoimento2} alt="depoimento2" />
          <img src={Depoimento3} alt="depoimento3" />
        </div>
        <div className='details order'>
          <h2>Depoimentos</h2>
          <p>
            Veja o que os nossos hóspedes disseram assim que terminaram as suas estadias.
          </p>
        </div>
      </div>
      <div className='overall-ratings'>
        <div className='image avaliacoes'>
          <img src={Depoimento4} alt="depoimento4" />
          <img src={Depoimento5} alt="depoimento5" />
          <img src={Depoimento6} alt="depoimento6" />
        </div>
        <div className='details'>
          <h2>Mais depoimentos</h2>
          <p>
            Mais alguns depoimentos, você pode acessar todos clicando&nbsp;
            <a href="https://www.airbnb.com.br/rooms/34527798/reviews?_set_bev_on_new_domain=1656800754_MTRhMWJhMDJmODEz&source_impression_id=p3_1656800755_SMH%2FCXQ5YNQ7p4yo" target={'_blank'} rel={'noreferrer'}>
              aqui.
            </a>
          </p>
        </div>
      </div>

    </div>
  )
}

export default Ratings