import React from 'react';
import './Footer.css';

import { RiWhatsappFill } from 'react-icons/ri';
import { RiInstagramFill } from 'react-icons/ri';
import { TbBrandAirbnb } from 'react-icons/tb';

const Footer = () => {
    return (
        <div id='footer'>
            <h3>Reserve agora</h3>
            <div id='social'>
                <a href="https://api.whatsapp.com/send?phone=5522981062471&text=Ol%C3%A1%2C%20quero%20informa%C3%A7%C3%B5es%20do%20apt!" target={'_blank'} rel={'noreferrer'}>
                    <RiWhatsappFill />
                </a>
                <RiInstagramFill />
                <a href='https://www.airbnb.com.br/rooms/34527798?_set_bev_on_new_domain=1656800754_MTRhMWJhMDJmODEz&source_impression_id=p3_1656800755_SMH%2FCXQ5YNQ7p4yo' target={'_blank'} rel={'noreferrer'}>
                    <TbBrandAirbnb />
                </a>
            </div>
            <div id='jv'>
                <p>&copy;2022 desenvolvido por: &nbsp;
                    <strong>
                        <a href='https://www.linkedin.com/in/jo%C3%A3o-vitor-matos-gouveia-14b71437/' target={'_blank'} rel={'noreferrer'}>
                            João Vitor Gouveia
                        </a>
                    </strong>
                </p>
            </div >
            <div id='bugs'>
                <p>Relate qualquer problema pelo WhatsApp</p>
            </div>
        </div>
    )
}

export default Footer